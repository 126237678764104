import React, { useState, useEffect } from "react";
import { FaMobileAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import logo from "../image/logo.png";
import desktopFooterBg from "../image/Footer.png";
import mobileFooterBg from "../image/Footer-mobile.png";

const Footer = () => {
  const [backgroundImage, setBackgroundImage] = useState(
    window.innerWidth > 768 ? desktopFooterBg : mobileFooterBg
  );

  useEffect(() => {
    const handleResize = () => {
      setBackgroundImage(window.innerWidth > 768 ? desktopFooterBg : mobileFooterBg);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); 
  return (
    <div className="footer" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="container footer-section">
        <div className="footer-column buttons-container1">
          <div className="contact-info">
            <FaMobileAlt />
            <span>9285416774</span>
          </div>
          <div className="contact-info">
            <FaEnvelope />
            <span>simbha@gmail.com</span>
          </div>
          <div className="contact-info">
            <FaMapMarkerAlt />
            <span>Chennai</span>
          </div>
        </div>

        <div className="footer-column logofooter">
          <img src={logo} alt="logo" />
          <div className="social-media-icons">
            <a href="https://facebook.com">
              <FaFacebook />
            </a>
            <a href="https://twitter.com">
              <FaTwitter />
            </a>
            <a href="https://instagram.com">
              <FaInstagram />
            </a>
            <a href="https://youtube.com">
              <FaYoutube />
            </a>
          </div>
        </div>
      </div>

      <div className="copyright">
        <hr />
        © {new Date().getFullYear()} Simbha Constructions by{" "}
        <a href="https://nurtureu.tech/">NurtureU Technologies</a>
      </div>
    </div>
  );
};

export default Footer;
