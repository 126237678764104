import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { FaUser, FaMobileAlt, FaEnvelope } from 'react-icons/fa';

const serviceId ='service_zjn4r8k';
const templateId ='template_4dm5qlj';
const publicKey = 'w1a056Gtctflsn7Z_';


const Form = () => {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [ownPlot, setOwnPlot] = useState('');
  const [constructionTimeframe, setConstructionTimeframe] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      from_mobile: mobile,
      from_ownplot: ownPlot,
      from_constructionTimeframe: constructionTimeframe,
      to_name: 'Simbha Constructions',
      message: `A customer has contacted you through the website. Here are their details:\n\nName: ${name}\n\nMobile: ${mobile}\n\nEmail: ${email}`,
    };

    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setName('');
        setMobile('');
        setEmail('');
        setOwnPlot('');
        setConstructionTimeframe('');
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 5000); 
      })
      .catch((error) => {
        console.error('Error sending email', error);
      });
  };

  return (
    <div className='container'>
      <form onSubmit={handleSubmit} className='form' action="#" method="post">
        <div className="form-row">
          <div className="form-group">
            <div className="icon-and-input">
              <FaUser/>
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                required
              />
            </div>
          </div>
          <div className="form-group">
            <div className="icon-and-input">
              <FaMobileAlt/>
              <input
                type="tel"
                name="mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                placeholder="Mobile Number"
                required
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <div className="icon-and-input">
              <FaEnvelope />
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label>Do you own a plot / land?</label>
            <br /><br />
            <div className="radio-group">
                <input
                  type="radio"
                  name="ownPlot"
                  value="Yes"
                  checked={ownPlot === "Yes"}
                  onChange={(e) => setOwnPlot(e.target.value)}
                  required
                />
                <label>Yes</label>
                 <input
                  type="radio"
                  name="ownPlot"
                  value="No"
                  checked={ownPlot === "No"}
                  onChange={(e) => setOwnPlot(e.target.value)}
                  required
                />
                  <label>No</label>
            </div>
          </div>
        </div>
        <div className="form-group1">
          <label htmlFor="constructionTimeframe">I want to start the construction in:</label>
          <br /><br />
          <select
            id="constructionTimeframe"
            name="constructionTimeframe"
            value={constructionTimeframe}
            onChange={(e) => setConstructionTimeframe(e.target.value)}
            required
          >
            <option value="">-select-</option>
            <option value="0-3 months">0-3 months</option>
            <option value="3-6 months">3-6 months</option>
            <option value="more than 6 months">More than 6 months</option>
          </select>
        </div>
        <button type="submit">GET QUOTE</button>
        {showSuccessMessage && <div style={{ color: 'black' }}>Form submitted successfully!</div>}
      </form>
    </div>
  );
}

export default Form;