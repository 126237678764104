import React, { useState } from "react";
import "../App.css";
import logo from "../image/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";

function Navbar() {
    const [showMediaIcons, setShowMediaIcons] = useState(false);

    const handleScrollToSection = (e, sectionId) => {
        e.preventDefault();
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
            setShowMediaIcons(false); // Close the mobile menu after clicking a link
        }
    };

    return (
        <nav className="main-nav">
            {/* Logo Section */}
            <div className="logo">
                <img
                    src={logo}
                    alt="Logo"
                    style={{ marginRight: "10px", height: "80px", width: "80px" }}
                />
            </div>

            {/* Menu Links */}
            <div className={showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"}>
                <ul>
                    <li>
                        <a
                            href="#about-us"
                            onClick={(e) => handleScrollToSection(e, "about-us")}
                            className="menu-items"
                        >
                            ABOUT US
                        </a>
                    </li>
                    <li>
                        <a
                            href="#construction-solutions"
                            onClick={(e) => handleScrollToSection(e, "construction-solutions")}
                            className="menu-items"
                        >
                            CONSTRUCTION SOLUTIONS
                        </a>
                    </li>
                    <li>
                        <Link to="#" className="menu-items">
                            PROJECTS
                        </Link>
                    </li>
                </ul>
            </div>

            {/* Hamburger Icon for Mobile View */}
            <div className="social-media">
                <div className="hamburger-menu">
                    <button
                        type="button"
                        onClick={() => setShowMediaIcons(!showMediaIcons)}
                        className="hamburger-button"
                        aria-label="Toggle navigation menu"
                    >
                        <GiHamburgerMenu color="#f9bd1c" size={30} />
                    </button>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
