import React from 'react';
import { Link } from 'react-router-dom';
import bannerImage from '../image/bannerimage.png';
import image3 from '../image/Simbha-image2.png';
import image5 from '../image/About-Us.png';
import image7 from '../image/Services1.png';
import image8 from '../image/Services2.png';
import image9 from '../image/Services3.png';
import image10 from '../image/Services4.png';
import image11 from '../image/Services5.png';
import facebook from '../image/facebook-icon.png';
import twitter from '../image/twitter-icon.png';
import instagram from '../image/insta-icon.png';
import linkedin from '../image/linked-in-icon.png';
import Form from './Form';
import Footer from './Footer';

const Home = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <div className="hm-pg" >
        {/* Banner Section */}
        <div className="banner">
          <img src={bannerImage} alt="Banner" />
        </div>

        {/* Form Container */}
        <div className="form-container">
          <h2>Your Home, Our Priority</h2><br />
          <Form />
        </div>

        {/* About Us Section */}
        <section className="about-us">
          <div id="about-us" className="section" >
            <div className="bg-image-container">
              <div className="content-overlay">
                <div className="about-img">
                  <img src={image5} alt='about us' />
                </div>
                <p className="description">
                  At Simbha Constructions, we are committed to making your dream home a reality. With unwavering dedication and expertise, we prioritize your home as our foremost concern. We understand the profound emotions tied to creating a new home for your family. With our expertise, we transform your dreams into reality, ensuring each corner echoes warmth and comfort. From concept to completion, we prioritize your happiness, making your home-building journey memorable and stress-free.
                </p>
              </div>
              <div className='bottom-session'>
                <div className='get-btn'>
                  <button className="common-button1" type="button" onClick={scrollToTop}>
                    GET QUOTE
                  </button>
                </div>

                <div className="follow-icon">
                  <p>Follow us</p>
                  <ul className='social-icons'>
                    <li><Link href="https://www.facebook.com" target="_blank" rel="noreferrer"><img src={facebook} alt="facebook" /></Link></li>
                    <li><Link href="https://twitter.com" target="_blank" rel="noreferrer"><img src={twitter} alt="twitter" /></Link></li>
                    <li><Link href="https://www.instagram.com" target="_blank" rel="noreferrer"><img src={instagram} alt="instagram" /></Link></li>
                    <li><Link href="https://www.linkedin.com" target="_blank" rel="noreferrer"><img src={linkedin} alt="linkedin" /></Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Construction Solutions Section */}
        <section className="construction">
          <div id="construction-solutions"></div>
          <div className="image-container">
            <div className="Construction-image" style={{
              position: 'relative', 
            }}>
              <img src={image3} alt="service" />
              <div className="buttons-container">
                <Link href="/" className="service-button">Construction Approval</Link>
                <Link href="/" className="service-button">Cost Estimation</Link>
                <Link href="/" className="service-button">Architectural Design</Link>
                <Link href="/" className="service-button">Construction</Link>
                <Link href="/" className="service-button">Interior Design</Link>
              </div>
            </div>
          </div>
        </section>
        {/* Process Section */}
        <div className="image">
          {/* <img src={image4} alt="process" /> */}
          <div className="blocks">
            <div className="block">
              <img src={image7} alt="process" />
              <div className="title">Cost Estimation</div>
            </div>
            <div className="block">
              <img src={image10} alt="process" />
              <div className="title">Design Process</div>
            </div>
            <div className="block">
              <img src={image9} alt="process" />
              <div className="title">Customization</div>
            </div>
            <div className="block">
              <img src={image8} alt="process" />
              <div className="title">Meet Our Experts</div>
            </div>
            <div className="block">
              <img src={image11} alt="process" />
              <div className="title">Track Construction</div>
            </div>
          </div>
          <button className="common-button" type="button" onClick={scrollToTop}>GET STARTED</button>
        </div>

        {/* Contact Section */}
        <div className="formimage">
          <div className="form-container1">
            <h2>Your Home, Our Priority</h2><br />
            <Form />
          </div>
          <div className="text-wrapper1">
            <h1>Ready to turn your vision into reality?</h1>
            <p>Contact Simbha Contraction today for new house or commercial property construction projects.Your home is our priority</p>
            <button className="contact-button">Get Started</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
