import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Navbar from './Component/Navbar';
import Home from "./Component/Home";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path='/' element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
